import { ApolloError, gql, useQuery } from '@apollo/client';
import { RuleFragment } from '../fragments/rule.fragments';
import { NBA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Rule } from 'generated/nba.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const RETRIEVE_NEXT_BEST_ACTION_RULES = gql`
query retrieveRulesQuery {
    retrieveRules {
    ${RuleFragment}
  }
}
`;

type ResultType = {
  retrieveRules: Rule[];
};

type UseRetrieveRules = () => {
  loading: boolean;
  error?: ApolloError;
  data?: ResultType;
};

const useRetrieveRulesQuery: UseRetrieveRules = () => {
  const { data, loading, error } = useQuery<ResultType>(
    RETRIEVE_NEXT_BEST_ACTION_RULES,
    {
      fetchPolicy: 'cache-and-network',
      context: { clientName: NBA_GRAPHQL_ENDPOINT },
    },
  );

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'retrieveRules.ts - useRetrieveRulesQuery',
    });
  }
  return {
    data: data || { retrieveRules: [] },
    loading,
    error,
  };
};

export default useRetrieveRulesQuery;
