import { FC } from 'react';
import FormLayoutContainer from '../FormLayout/FormLayoutContainer';
import {
  // ruleFormFullHeight,
  width50,
  width33,
  ruleFormWrapper,
} from './RuleForm.style';
import { RuleFormPropsType } from './RuleForm.types';
// import FormLayoutContainer from 'components/FormLayout/FormLayoutContainer';
import FormContainer from 'modules/nba/components/FormContainer/FormContainer';
import useRuleCreate from 'modules/nba/components/RuleForm/RuleForm.hook';
import Box from 'yoda-ui/Box';
// import DateRangePicker from 'yoda-ui/Form/DateRangePicker';
// import SelectYoda from 'yoda-ui/Form/SelectYoda';
// import MultiSelectYoda from 'yoda-ui/Form/SelectYoda/MultiSelectYoda';
import SelectYoda from 'yoda-ui/Form/SelectYoda/SelectYoda';
import TextYoda from 'yoda-ui/Form/TextYoda';
// import Loader from 'yoda-ui/Loader';
import {
  YodaJustifyContent,
  // YodaJustifyContent,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

const RuleForm: FC<RuleFormPropsType> = ({
  rule,
  submitCallback,
  cancelCallback,
}) => {
  // const flags = useFlags();

  const {
    cancelButtonConfig,
    ruleFormConfig,
    providerFields,
    submitButtonConfig,
    // loading = false,
  } = useRuleCreate(rule, submitCallback, cancelCallback);

  return (
    <FormContainer
      providerFields={ providerFields }
      cancelButtonConfig={ cancelButtonConfig }
      submitButtonConfig={ submitButtonConfig }
    >
      <FormLayoutContainer
        justifyContent={ YodaJustifyContent.spaceBetween }
        flexDirection="column"
      >
        <Box sx={ ruleFormWrapper }>
          <Box paddingTop={ YodaSpacing.small } width={ width50 }>
            <SelectYoda { ...ruleFormConfig.COUNTRY } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width50 }>
            <TextYoda { ...ruleFormConfig.USE_CASE_NAME } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.BUSINESS_UNIT } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.IS_BU_SELECTED } />
          </Box>
        </Box>
        <Box sx={ ruleFormWrapper }>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.HCP_SEGMENT } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.CHANNEL_AFFINITY } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.BACK_DAYS } />
          </Box>
          <Box paddingTop={ YodaSpacing.small } width={ width33 }>
            <TextYoda { ...ruleFormConfig.FUTURE_DAYS } />
          </Box>
        </Box>
      </FormLayoutContainer>
    </FormContainer>
  );
};

export default RuleForm;
