import { useTranslation } from 'react-i18next';
import EmptySVG from 'basics/images/Empty-State.svg';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/Box';
import List from 'yoda-ui/List';
import ListItem from 'yoda-ui/List/ListItem';
import ListItemText from 'yoda-ui/List/ListItemText';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const NotFound = () => {
  const { t } = useTranslation();
  const errorItems = [
    t('common.wording.error400'),
    t('common.wording.error401'),
    t('common.wording.error403'),
    t('common.wording.error404'),
    t('common.wording.error410'),
  ];

  return (
    <LargeContainer
      display="column"
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box fontSize={ YodaFontSize.xxxxLarge } color={ YodaColors.gray5 }>
        { t('common.wording.error4xxTitle') }
      </Box>
      <Box
        marginTop={ YodaSpacing.small }
        marginBottom={ YodaSpacing.medium }
        fontSize={ YodaFontSize.xLarge }
        color={ YodaColors.gray4 }
      >
        { t('common.wording.error4xxDescription') }

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop={ YodaSpacing.medium }
        >
          <Box flexGrow="1">
            <img alt="" src={ EmptySVG } width="100%" />
          </Box>

          <Box
            flexGrow="2"
            paddingLeft={ YodaSpacing.xLarge }
          >
            <List dense={ true }>
              {
                errorItems.map((item, index) => (
                  <ListItem key={ `errorItem-${index}` } disableGutters={ true } divider={ true }>
                    <ListItemText primary={ item } />
                  </ListItem>
                ))
              }
            </List>
          </Box>
        </Box>
      </Box>
    </LargeContainer>
  );
};

export default NotFound;
