import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BIOGEN_LOGO_FOOTER } from 'basics/constants/images.constants';
import { homeRoute } from 'basics/constants/routes.constants';
import { useGetConfig } from 'basics/utils/getConfig.hook';
import { logoStyle } from 'components/Layout/Footer/Footer.styles';
import Box from 'yoda-ui/Box';
import Grid from 'yoda-ui/Grid';
import Link from 'yoda-ui/Links/Link';
import {
  YodaColors,
  YodaFontWeight,
  YodaGridCellSize,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

const footerStyles = {
  grid: {
    width: '100%',
    backgroundColor: `${YodaColors.gray5}`,
    margin: 0,
    marginTop: 'auto',
  },
  image: {
    width: '9.125rem',
  },
};

const Footer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { title, support } = useGetConfig();

  return (
    <Grid container spacing={ YodaSpacing.xxxSmall } sx={ footerStyles.grid }>
      <Grid item xs={ YodaGridCellSize.xSmall }>
        <Box sx={ logoStyle } onClick={ () => navigate(homeRoute) }>
          <img src={ BIOGEN_LOGO_FOOTER } alt="biogen-logo" style={ footerStyles.image } />
        </Box>
      </Grid>
      <Grid item xs={ YodaGridCellSize.xSmall }>
        <Box marginLeft={ YodaSpacing.large } paddingBottom={ YodaSpacing.xLarge }>
          <Box
            color={ YodaColors.white }
            marginTop={ YodaSpacing.xxLarge }
            component="h2"
          >
            { title }
          </Box>
          <Box color={ YodaColors.white } marginTop={ YodaSpacing.medium }>
            <Link
              href={ `${support}` }
              target="_blank"
              color={ YodaColors.inherit }
            >
              { t('footer_support') }
            </Link>
          </Box>
          <Box
            color={ YodaColors.white }
            marginTop={ YodaSpacing.xxxSmall }
            fontWeight={ YodaFontWeight.medium }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
