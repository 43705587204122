import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  homeRoute,
  loginRoute,
  nbaRuleCreateRoute,
  nbaRuleEditRoute,
} from 'basics/constants/routes.constants';
import IdleTimerDialog from 'components/IdleTimerDialog';
import Nba from 'modules/nba/routes/Nba/Nba';
import RuleCreate from 'modules/nba/routes/RuleCreate/RuleCreate';
import RuleEdit from 'modules/nba/routes/RuleEdit/RuleEdit';
import NotFound from 'routes/AuthenticatedApp/NotFound';

const AuthenticatedApp: FC = () => {
  return (
    <>
      <IdleTimerDialog />
      <Routes>
        <Route path='*' element={ <NotFound /> } />
        <Route path={ loginRoute } element={ <Navigate to='/' replace /> } />
        <Route path={ homeRoute } element={ <Nba /> } />
        <Route path={ nbaRuleEditRoute } element={ <RuleEdit /> } />
        <Route path={ nbaRuleCreateRoute } element={ <RuleCreate /> } />
      </Routes>
    </>
  );
};

export default AuthenticatedApp;
