export const RuleFragment = `
  _ID
  COUNTRY
  USE_CASE_NAME
  HCP_SEGMENT
  CHANNEL_AFFINITY
  BACK_DAYS
  FUTURE_DAYS
  BUSINESS_UNIT
  IS_BU_SELECTED
`;
