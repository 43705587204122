import { FC } from 'react';
import {
  formContainerStyle,
  formContainerContentStyle,
  formContainerActionsStyle,
} from './FormContainer.style';
import { FormContainerTypes } from './FormContainer.types';
import ActionButton from 'components/ActionButton/ActionButton';
import ContentCard from 'components/ContentCard';
import LargeContainer from 'components/Layout/LargeContainer';
import { YodaFormProvider } from 'services/yodaForm';
import Box from 'yoda-ui/Box';

const FormContainer: FC<FormContainerTypes> = ({
  providerFields,
  cancelButtonConfig,
  submitButtonConfig,
  children,
}) => {
  return (
    <LargeContainer>
      <YodaFormProvider { ...providerFields }>
        <Box sx={ formContainerStyle }>
          <ContentCard sx={ formContainerContentStyle }>{ children }</ContentCard>
          <Box sx={ formContainerActionsStyle }>
            {
              cancelButtonConfig ? (
                <ActionButton { ...cancelButtonConfig } />
              ) : (
                <Box></Box>
              )
            }
            { submitButtonConfig && <ActionButton { ...submitButtonConfig } /> }
          </Box>
        </Box>
      </YodaFormProvider>
    </LargeContainer>
  );
};

export default FormContainer;
