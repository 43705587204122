import { FC } from 'react';
// import { useTranslation } from 'react-i18next';
import RuleDashboardList from './RuleDashboardGridList/RuleDashboardList';
import ContentCard from 'components/ContentCard';
// import CountCepWidget from 'modules/maya/components/CountWidgets/CountCepWidget/CountCepWidget';
// import CountCiWidget from 'modules/maya/components/CountWidgets/CountCiWidget/CountCiWidget';
// import CountTacticsWidget from 'modules/maya/components/CountWidgets/CountTacticsWidget/CountTacticsWidget';
// import CountTargetsWidget from 'modules/maya/components/CountWidgets/CountTargetsWidget/CountTargetsWidget';
// import CepDashBoardList from 'modules/maya/components/Dashboard/CepDashboardGridlist/CepDashboardList';
// import CiDashboardList from 'modules/maya/components/Dashboard/CiDashboardGridlist/CiDashboardList';
import {
  dashboardSummaryStats,
  gridListDashboard,
} from 'modules/nba/components/Dashboard/Dashboard.style';
// import DashboardFilters from 'modules/maya/components/DashboardFilters/DashboardFilters';
// import useDashboardFilters from 'modules/maya/components/DashboardFilters/DashboardFilters.hook';
import Heading from 'modules/nba/components/Heading';
import Box from 'yoda-ui/Box';

const Dashboard: FC = () => {
  //   const { t } = useTranslation();
  //   const {
  //     providerFields,
  //     dashboardFiltersConfig,
  //     handleChangeTA,
  //     handleChangeProduct,
  //     submitButtonConfig,
  //     resetButtonConfig,
  //     filters,
  //   } = useDashboardFilters();

  return (
    <Box flexDirection="column">
      <Heading>{ 'NBA Rules' }</Heading>

      { /* <DashboardFilters
        providerFields={ providerFields }
        dashboardFiltersConfig={ dashboardFiltersConfig }
        handleChangeTA={ handleChangeTA }
        handleChangeProduct={ handleChangeProduct }
        submitButtonConfig={ submitButtonConfig }
        resetButtonConfig={ resetButtonConfig }
      /> */ }

      <Box>
        <ContentCard>
          <Heading>{ 'Summary' }</Heading>

          <Box sx={ dashboardSummaryStats }>
            { /* <CountCiWidget filters={ filters } />
            <CountCepWidget filters={ filters } />
            <CountTacticsWidget filters={ filters } />
            <CountTargetsWidget filters={ filters } /> */ }
          </Box>
        </ContentCard>

        <Box sx={ gridListDashboard }>
          <RuleDashboardList></RuleDashboardList>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
