import { GridColDef } from '@mui/x-data-grid';

const useRuleDashboardListConfig = () => {
  const rulesTitleFieldConfig = {
    label: 'NBA Rules',
    name: 'COUNTRY',
    placeholder: 'Rules',
    defaultValue: '',
    required: false,
  };

  const rulesListColumnConfig: GridColDef[] = [
    {
      field: '_ID',
      headerName: 'ID',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'COUNTRY',
      headerName: 'Country',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'USE_CASE_NAME',
      headerName: 'Use Case',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'HCP_SEGMENT',
      headerName: 'HCP Segment',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'CHANNEL_AFFINITY',
      headerName: 'Channel Affinity',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'BACK_DAYS',
      headerName: 'Back Days',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'FUTURE_DAYS',
      headerName: 'Future Days',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'BUSINESS_UNIT',
      headerName: 'Business Unit',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
    {
      field: 'IS_BU_SELECTED',
      headerName: 'BU Selected',
      flex: 1,
      sortable: false,
      cellClassName: 'gridlist-cell-bold',
    },
  ];

  return {
    rulesListColumnConfig,
    rulesTitleFieldConfig,
  };
};

export default useRuleDashboardListConfig;
