import { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { homeRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import {
  RuleFormCancelCallbackType,
  RuleFormSubmitCallbackType,
} from 'modules/nba/components/RuleForm/RuleForm.types';
import { useUpdateRuleMutation } from 'modules/nba/graphql/mutations/updateRule';
import useGetRuleByIdQuery from 'modules/nba/graphql/queries/getRuleById';

const useRuleEdit = () => {
  const navigate = useNavigate();

  const [updateRule] = useUpdateRuleMutation();

  // const [updateCustomerInitiative] = useUpdateCustomerInitiativeMutation();

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const location = useLocation();
  const currentURL = location.pathname;

  const [loading, setLoading] = useState(true);

  const rule = useGetRuleByIdQuery(urlParams.get('id')).data;
  useEffect(() => {
    if (rule) {
      setLoading(false);
    }
  }, [rule, setLoading]);

  const handleCancelRuleForm: RuleFormCancelCallbackType = () => {
    navigate(homeRoute);
  };

  const handleSaveRuleForm: RuleFormSubmitCallbackType = async (
    ruleFormData,
  ) => {
    // const customerInitiativeData = await updateCustomerInitiative(
    //   rule?._id || null,
    //   { ...rule, isTemplate: false }
    // );
    const ruleData = await updateRule(
      rule?._ID || null,
      {
        ...ruleFormData,
      },
      true,
    );

    if (ruleData) {
      succesToast(`Rule ${rule?._ID} updated`);
      navigate(homeRoute);
    } else {
      warningToast('Cannot update rule');
    }
  };

  return {
    loading,
    rule,
    currentURL,
    handleSaveRuleForm,
    handleCancelRuleForm,
  };
};

export default useRuleEdit;
