import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { heading1Style, heading2Style } from './Heading.style';
import { HeadingProps } from './Heading.types';
import Box from 'yoda-ui/Box';

const Heading: FC<HeadingProps> = ({ level, children }) => {
  const headingLevel = (!level || level < 0) ? 1 : level;
  const headingsConfig: { level: number; style: SxProps<Theme> }[] = [
    { level: 1, style: heading1Style },
    { level: 2, style: heading2Style },
  ];
  const headingConfig = headingsConfig.find((config) => {
    return config.level === headingLevel;
  }) || headingsConfig[0];

  return (
    <Box sx={ headingConfig.style }>
      { children }
    </Box>
  );
};

export default Heading;
