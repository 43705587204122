/* eslint-disable @typescript-eslint/no-use-before-define */
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import {
  TIME_20_MINUTES_IN_MS,
  TIME_FIVE_MINUTES_IN_SECONDS,
  TIME_ONE_SECOND_IN_MS,
  TIME_ONE_SECOND_IN_SECONDS,
} from 'basics/constants/common.constants';
import { splitSecondsTimeInMinAndSec } from 'basics/utils/dates/dates.utils';
import useAuth from 'hooks/useAuth';
import useConst from 'hooks/useConst';

const useIdleTimerDialog = () => {
  const { t } = useTranslation();
  const { automaticLogout, automaticLogoutBcData } = useAuth();
  const [idleDialogIsOpen, setIdleDialogIsOpen] = useState(false);
  const [count, setCount] = useState(TIME_FIVE_MINUTES_IN_SECONDS);
  const [dialogContent, setDialogContent] = useState('');
  const intervalCountdown = useRef<NodeJS.Timer>();
  const broadcastChannel = useConst<BroadcastChannel>(new BroadcastChannel('idle'));

  broadcastChannel.onmessage = (event: MessageEvent) => {
    if (event.data === automaticLogoutBcData) {
      automaticLogout();
    }
  };

  useEffect(() => {
    if (count > 0) {
      setDialogContent(`${t('idleTimerDialog_content', {
        time: splitSecondsTimeInMinAndSec({
          time: count,
          minutesLabel: t('short_minutes_label'),
          secondsLabel: t('short_seconds_label'),
        }),
      })}`);
    } else {
      automaticLogout();
      broadcastChannel.postMessage(automaticLogoutBcData);
    }
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  const onIdleTimerIdle = () => {
    setIdleDialogIsOpen(true);
    countDownTimerStart();
    intervalCountdown.current = setInterval(() => {
      setCount((previous) => previous - TIME_ONE_SECOND_IN_SECONDS);
    }, TIME_ONE_SECOND_IN_MS);
  };

  const { reset: idleTimerReset } = useIdleTimer({
    timeout: TIME_20_MINUTES_IN_MS,
    onIdle: onIdleTimerIdle,
    stopOnIdle: true,
    crossTab: {
      channelName: 'react-idle-timer',
      emitOnAllTabs: true,
    },
  });

  const onCountDownActive = () => {
    setIdleDialogIsOpen(false);
    if (intervalCountdown.current) {
      clearInterval(intervalCountdown.current);
    }
    setCount(TIME_FIVE_MINUTES_IN_SECONDS);
    idleTimerReset();
  };

  const { start: countDownTimerStart } = useIdleTimer({
    timeout: 1, // instantly idle
    startOnMount: false,
    startManually: true,
    onActive: onCountDownActive,
    crossTab: {
      channelName: 'countdown',
      emitOnAllTabs: true,
    },
    events: ['mousedown'],
  });

  return {
    count,
    dialogContent,
    idleDialogIsOpen,
    t,
  };
};

export default useIdleTimerDialog;
