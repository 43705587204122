import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export const homeBannerWrapper = {
  boxSizing: 'border-box',
  marginLeft: -YodaSpacing.xLarge,
  marginRight: -YodaSpacing.xLarge,
  position: 'relative',
};

export const homeBannerTitle = {
  minHeight: '300px',
  paddingTop: '100px',
  paddingBottom: '100px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
};

export const homeBannerTitleText = {
  fontSize: YodaFontSize.xxxxLarge,
  color: YodaColors.white,
  paddingRight: YodaSpacing.xLarge,
  paddingLeft: YodaSpacing.xxxxLarge,
  marginLeft: YodaSpacing.xxxxLarge,
  maxWidth: '40%',
};

export const HelloWorldBlockStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
};

