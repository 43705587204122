import { FC } from 'react';
import { ContainerPropsType } from './Container/Container.types';
import Box from 'yoda-ui/Box';

const SmallContainer: FC<ContainerPropsType> = ({ children, ...rest }) => (
  <Box
    position="relative"
    width="70%"
    maxWidth="74rem"
    alignSelf="center"
    { ...rest }
  >
    { children }
  </Box>
);

export default SmallContainer;
