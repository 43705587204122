import { ApolloError, gql, useQuery } from '@apollo/client';
import { RuleFragment } from '../fragments/rule.fragments';
import { NBA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Rule } from 'generated/nba.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_RULE_BY_ID = gql`
query GetRuleByIdQuery($id: String!) {
    getRuleById(id: $id) {
    ${RuleFragment}
  }
}
`;

type ResultType = {
  getRuleById: Rule;
};

type UseGetRuleById = (ruleId: string | null) => {
  loading: boolean;
  error?: ApolloError;
  data?: Rule;
};

const useGetRuleByIdQuery: UseGetRuleById = (ruleId) => {
  const { data, loading, error } = useQuery<ResultType>(GET_RULE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: NBA_GRAPHQL_ENDPOINT },
    skip: ruleId === null,
    variables: {
      id: ruleId,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getRuleById.ts - useGetRuleByIdQuery',
    });
  }
  return {
    data: data?.getRuleById,
    loading,
    error,
  };
};

export default useGetRuleByIdQuery;
