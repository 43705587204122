import { FormControl } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useListPagination from './GridListPagination.hook';
import { GridListPaginationPropsType } from './GridListPagination.types';
import { PAGINATION_PAGE_SIZE_STEPS } from 'modules/nba/basics/constants/common.constant';
import Select from 'yoda-ui/Form/Select';
import MenuItem from 'yoda-ui/MenuItem';
import Pagination from 'yoda-ui/Pagination';

const GridListPagination: FC<GridListPaginationPropsType> = ({
  pageSize,
  setPageSize,
}) => {
  const {
    page,
    pageCount,
    displayedCount,
    onPageChange,
    rowsCount,
    onPageSizeChange,
  } = useListPagination(pageSize, setPageSize);
  const { t } = useTranslation();
  const paginationResultCountLabel = t(
    'maya_list_pagination_result_count_label',
    { displayedCount, rowsCount },
  );

  return (
    <>
      <FormControl>
        <Select value={ pageSize } onChange={ onPageSizeChange }>
          {
            PAGINATION_PAGE_SIZE_STEPS.map((pageSizeStep: number) => {
              return (
                <MenuItem key={ pageSizeStep } value={ pageSizeStep }>
                  { pageSizeStep }
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      <>{ paginationResultCountLabel }</>
      <Pagination
        color="primary"
        count={ pageCount }
        page={ page + 1 }
        size="small"
        onChange={ onPageChange }
      />
    </>
  );
};

export default GridListPagination;
