import { getConfigVar } from 'config/config';

const CDN_DNS = getConfigVar('REACT_APP_CDN_DNS');
const cdnImagePath = `https://${CDN_DNS}/images`;

export const MODULES_LOGO_PATH = `${cdnImagePath}/backOffice/modules`;

export const SCHEDULE_IMAGE = `${cdnImagePath}/Schedule.png`;
export const BACKOFFICE_LOGO = `${cdnImagePath}/backOffice/biogenlinc_bo_logo.svg`;
export const BIOGEN_LOGO_FOOTER = `${cdnImagePath}/backOffice/bo_logo_dark.svg`;
export const BO_HOME_BACKGROUND = `${cdnImagePath}/backOffice/bo_home_background.png`;

export const placeholderFilePath = 'https://via.placeholder.com/110x80';
