import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { fromUnitsToRem, YodaSpacing, YodaColors, YodaFontSize } from 'yoda-ui/yodaTheme';

export const heading1Style: SxProps<Theme> = {
  marginBottom: fromUnitsToRem(YodaSpacing.xxLarge),
  fontSize: YodaFontSize.xxLarge,
  color: YodaColors.gray5,
};

export const heading2Style: SxProps<Theme> = {
  marginBottom: YodaSpacing.small,
  fontSize: YodaFontSize.large,
  color: YodaColors.gray5,
};
