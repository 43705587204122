import { FC } from 'react';
import { nbaLayoutStyle, nbaLayoutContentStyle } from './NbaLayout.style';
import { NbaLayoutPropsType } from './NbaLayout.types';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/Box';

const NbaLayout: FC<NbaLayoutPropsType> = ({ children }) => {
  return (
    <LargeContainer sx={ nbaLayoutStyle }>
      <Box sx={ nbaLayoutContentStyle }>
        <LargeContainer flexDirection="column" marginX="auto" height="100%">
          { children }
        </LargeContainer>
      </Box>
    </LargeContainer>
  );
};

NbaLayout.displayName = 'NbaLayout';

export default NbaLayout;
