import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { FC, useMemo } from 'react';
import { SxProps, YodaBorderRadius } from 'yoda-ui/yodaTheme';

export type DialogProps = Omit<MuiDialogProps, 'sx'> & {
  borderRadius?: YodaBorderRadius;
  sx?: SxProps;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const getDialogStyle = ((borderRadius: YodaBorderRadius, sx?: SxProps | any) => ({
  ...sx,
  '& .MuiPaper-rounded': {
    borderRadius,
    ...(sx && '& .MuiPaper-rounded' in sx && sx['& .MuiPaper-rounded']),
  },
}));

const Dialog: FC<DialogProps> = ({ borderRadius = YodaBorderRadius.none, sx, ...rest }) => {
  const dialogStyle = useMemo(() => getDialogStyle(borderRadius, sx), [borderRadius, sx]);
  return (
    <MuiDialog
      sx={ dialogStyle }
      { ...rest }
    />
  );
};

export default Dialog;
