import { FC } from 'react';
import { BO_HOME_BACKGROUND } from 'basics/constants/images.constants';
import LargeContainer from 'components/Layout/LargeContainer';
import Dashboard from 'modules/nba/components/Dashboard/Dashboard';
import NbaLayout from 'modules/nba/components/NbaLayout/NbaLayout';
import { homeBannerWrapper } from 'modules/nba/routes/Nba/Nba.styles';
import Box from 'yoda-ui/Box/Box';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const Nba: FC = () => {
  return (
    <LargeContainer
      flexDirection="column"
      marginBottom={ YodaSpacing.xLarge }
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box sx={ homeBannerWrapper }>
        { /* <Box sx={ homeBannerTitle }>
          <Box sx={ homeBannerTitleText }>
            { 'Hi new NBA project' }
          </Box>
        </Box> */ }
        <img
          src={ BO_HOME_BACKGROUND }
          alt="bo-home-background"
          style={
            {
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: '-1',
            }
          }
        />
      </Box>
      <NbaLayout>
        <Dashboard />
      </NbaLayout>
    </LargeContainer>
  );
};

export default Nba;
