import { FC } from 'react';
import NavbarContent from './NavbarContent';
import { BACKOFFICE_LOGO } from 'basics/constants/images.constants';
import AppBar from 'yoda-ui/AppBar';
import Box from 'yoda-ui/Box';
import Toolbar from 'yoda-ui/Toolbar';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const NavBar: FC = () => {
  return (
    <AppBar position="fixed">
      <Box paddingX={ YodaSpacing.small }>
        <Toolbar>
          <Box display="flex" flex="column" justifyContent="space-between" width="100%">
            <Box height="3rem">
              <img src={ BACKOFFICE_LOGO } alt="biogen-logo" style={ { height: 'inherit' } } />
            </Box>
            <Box display="flex" height="2.5rem">
              <NavbarContent />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;

