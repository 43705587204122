import { FC } from 'react';
import Box from 'yoda-ui/Box';
import { ButtonType } from 'yoda-ui/Button/Button.types';
import Loader from 'yoda-ui/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

export type LoaderButtonContentProps = {
  buttonType?: ButtonType;
};

const LoaderButtonContent: FC<LoaderButtonContentProps> = ({ buttonType }) => (
  <Box display="flex">
    <Loader center size={ 1 } paddingX={ 0 } color={ buttonType === ButtonType.neutral ? YodaColors.primaryBlue : YodaColors.white } />
  </Box>
);

export default LoaderButtonContent;
