import { useTranslation } from 'react-i18next';
import { string } from 'yup';

const maxTitleLength = 80;
// const maxDescriptionLength = 255;
// const maxProblemStatementLength = 255;
// const maxSolutionCoreStoryLength = 1000;
// const maxSuccessMesureLength = 255;
// const maxTotalBudgetLength = 255;

const useRuleFormSchema = () => {
  const { t } = useTranslation();
  // const countriesAndRegion = isTemplate
  //   ? [...Object.keys(InitiativeCountry), ...Object.keys(TemplateRegion)]
  //   : Object.keys(InitiativeCountry);

  const requiredLabel = t('form_errors_required');
  const maxLengthTitleMessage = `${t(
    'maya_customer_initiative_form_title_error_max_char',
    {
      count: maxTitleLength,
    },
  )}`;

  return {
    _ID: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    BACK_DAYS: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    CHANNEL_AFFINITY: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    COUNTRY: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    FUTURE_DAYS: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    HCP_SEGMENT: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    USE_CASE_NAME: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    BUSINESS_UNIT: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
    IS_BU_SELECTED: string()
      .required(requiredLabel)
      .max(maxTitleLength, maxLengthTitleMessage),
  };
};

export default useRuleFormSchema;
