import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useRuleFormSchema from './RuleForm.schema';
// import useCustomerInitiativeFormSchema from "./CustomerInitiativeForm.schema";
// import { CustomerInitiativeFormConfigType } from "./CustomerInitiativeForm.types";
import { RuleFormConfigType } from './RuleForm.types';
// import {
//   mayaCiTemplateCreateRoute,
//   mayaCiTemplateEditRoute,
// } from "basics/constants/routes.constants";
import { nbaRuleEditRoute } from 'basics/constants/routes.constants';
import initiativeCountriesAndRegionOptions from 'basics/options/countriesAndRegion.options';
import initiativeCountryOptions from 'basics/options/country.options';
// import useProductOptions from "basics/options/product.options";
// import useTherapeuticAreaOptions from "basics/options/therapeuticArea.options";
import { Rule } from 'generated/nba.types';
import { ruleToForm } from 'modules/nba/basics/transformers/Rule.transformer';
// import { CiStatus } from "modules/maya/basics/enums/maya.enums";
// import biogenFunctionOptions from "modules/maya/basics/options/biogenFunction.options";
// import useStrategicImperativeOptions from "modules/maya/basics/options/strategicImperative.options";
// import { ciToForm } from "modules/maya/basics/transformers/CustomerInitiative.transformer";

const useRuleFormConfig = (rule: Rule | undefined) => {
  // const { t } = useTranslation();
  const location = useLocation();
  const currentURL = location.pathname;
  // const templateRoute = [mayaCiTemplateCreateRoute, mayaCiTemplateEditRoute];
  //   const countryOptions = templateRoute.includes(currentURL)
  //     ? initiativeCountriesAndRegionOptions
  //     : initiativeCountryOptions;

  const templateRoute = [nbaRuleEditRoute];
  const countryOptions = templateRoute.includes(currentURL)
    ? initiativeCountriesAndRegionOptions
    : initiativeCountryOptions;
  // const isTemplate = templateRoute.includes(currentURL);
  // const isTemplate = templateRoute.includes(currentURL);
  // const customerInitiativeFormSchema = useCustomerInitiativeFormSchema(isTemplate);
  const ruleFormSchema = useRuleFormSchema();
  const defaultFormValue = useMemo(() => ruleToForm(rule), [rule]);

  const ruleFormConfig: RuleFormConfigType = {
    // _ID: {
    //   label: 'Snowflake ID',
    //   name: 'ID',
    //   placeholder: '0',
    //   required: true,
    //   defaultValue: defaultFormValue._ID,
    //   validation: ruleFormSchema._ID.trim(),
    //   disabled: true,
    // },
    BACK_DAYS: {
      label: 'Back Days',
      name: 'BACK_DAYS',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.BACK_DAYS,
      validation: ruleFormSchema.BACK_DAYS.trim(),
      disabled: false,
    },
    CHANNEL_AFFINITY: {
      label: 'Channel Affinity',
      name: 'CHANNEL_AFFINITY',
      placeholder: 'F2F',
      required: true,
      defaultValue: defaultFormValue.CHANNEL_AFFINITY,
      validation: ruleFormSchema.CHANNEL_AFFINITY.trim(),
      disabled: false,
    },
    COUNTRY: {
      label: 'Country',
      name: 'COUNTRY',
      placeholder: 'GB',
      required: true,
      defaultValue: defaultFormValue.COUNTRY,
      options: countryOptions,
      validation: ruleFormSchema.COUNTRY.trim(),
      disabled: false,
    },
    FUTURE_DAYS: {
      label: 'Future Days',
      name: 'FUTURE_DAYS',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.FUTURE_DAYS,
      validation: ruleFormSchema.FUTURE_DAYS.trim(),
      disabled: false,
    },
    HCP_SEGMENT: {
      label: 'HCP Segment',
      name: 'HCP_SEGMENT',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.HCP_SEGMENT,
      validation: ruleFormSchema.HCP_SEGMENT.trim(),
      disabled: false,
    },
    USE_CASE_NAME: {
      label: 'UC Name',
      name: 'USE_CASE_NAME',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.USE_CASE_NAME,
      validation: ruleFormSchema.USE_CASE_NAME.trim(),
      disabled: false,
    },
    BUSINESS_UNIT: {
      label: 'Business Unit',
      name: 'BUSINESS_UNIT',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.BUSINESS_UNIT,
      validation: ruleFormSchema.BUSINESS_UNIT.trim(),
      disabled: false,
    },
    IS_BU_SELECTED: {
      label: 'BU Selected',
      name: 'IS_BU_SELECTED',
      placeholder: '0',
      required: true,
      defaultValue: defaultFormValue.IS_BU_SELECTED,
      validation: ruleFormSchema.IS_BU_SELECTED.trim(),
      disabled: false,
    },
  };

  return { ruleFormConfig };
};

export default useRuleFormConfig;
