import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { useCurrentUserState } from 'contexts/user';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';

const Routes = () => {
  const { userLoading, user } = useCurrentUserState();

  if (userLoading) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default Routes;
