import { Rule } from 'generated/nba.types';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';

export const ruleToBE = (data: YodaFieldValue) => {
  return {
    _ID: data.ID,
    BACK_DAYS: parseFloat(data.BACK_DAYS),
    CHANNEL_AFFINITY: data.CHANNEL_AFFINITY,
    COUNTRY: data.COUNTRY,
    FUTURE_DAYS: parseFloat(data.FUTURE_DAYS),
    HCP_SEGMENT: data.HCP_SEGMENT,
    USE_CASE_NAME: data.USE_CASE_NAME,
    BUSINESS_UNIT: data.BUSINESS_UNIT,
    IS_BU_SELECTED: data.IS_BU_SELECTED,
  };
};

export const ruleToForm = (rule: Partial<Rule> | undefined | null) => {
  return {
    BACK_DAYS: rule?.BACK_DAYS?.toString() || '',
    CHANNEL_AFFINITY: rule?.CHANNEL_AFFINITY?.toString() || '',
    COUNTRY: rule?.COUNTRY || '',
    FUTURE_DAYS: rule?.FUTURE_DAYS?.toString() || '',
    HCP_SEGMENT: rule?.HCP_SEGMENT?.toString(),
    USE_CASE_NAME: rule?.USE_CASE_NAME?.toString(),
    BUSINESS_UNIT: rule?.BUSINESS_UNIT?.toString(),
    IS_BU_SELECTED: rule?.IS_BU_SELECTED?.toString(),
  };
};
