import { DialogTitleProps } from '@mui/material';
import { FC, MouseEvent } from 'react';
import Box from 'yoda-ui/Box';
import DialogTitle from 'yoda-ui/Dialogs/Dialog/DialogTitle';
import Icon, { Icons } from 'yoda-ui/Icons/Icon/Icon';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

export type DefaultDialogTitleProps = DialogTitleProps & {
  cancelDialog?: (event?: MouseEvent<SVGSVGElement>) => void;
  fontSize?: YodaFontSize;
};

const DefaultDialogTitle: FC<DefaultDialogTitleProps> = ({
  cancelDialog,
  fontSize = YodaFontSize.xLarge,
  children,
}) => (
  <DialogTitle>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box fontSize={ fontSize }>
        { children }
      </Box>
      {
        cancelDialog
          && <Box marginLeft={ YodaSpacing.small }>
            <Icon
              name={ Icons.close }
              fontSize="medium"
              style={ { fill: YodaColors.gray3, cursor: 'pointer' } }
              onClick={ cancelDialog }
            />
          </Box>
      }
    </Box>
  </DialogTitle>
);

export default DefaultDialogTitle;
