import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

export const ruleFormFullHeight: SxProps<Theme> = {
  paddingTop: YodaSpacing.large,
  width: 'calc(50% - 20px)',
  alignSelf: 'stretch',
  '& > *': {
    height: '100%',
    '& > div:first-of-type': {
      height: '100%',
    },
  },
  '& .MuiInputBase-inputMultiline': {
    height: '100% !important',
  },
};

export const ruleFormWrapper: SxProps<Theme> = {
  justifyContent: YodaJustifyContent.spaceBetween,
  display: 'flex',
};

export const width50 = 'calc(50% - 20px)';
export const width33 = 'calc(33.33% - 26.66px)';
