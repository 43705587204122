import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { homeRoute } from 'basics/constants/routes.constants';
import oktaClient from 'services/okta';

const idleKey = 'idle';

export type Location = {
  state?: {
    from?: {
      path?: string;
      pathname?: string;
      search?: string;
    };
  };
};

const useAuth = () => {
  const automaticLogoutBcData = 'logout';
  const location = useLocation() as Location;
  const from = location?.state?.from?.pathname || homeRoute;
  const queries = location?.state?.from?.search || '';
  const redirectUrl = `${from}${queries}`;

  const setUserIsIdle = useCallback(() => {
    window.localStorage.setItem(idleKey, idleKey);
  }, []);

  const getUserIsIdle = useCallback(() => window.localStorage.getItem(idleKey), []);

  const resetIdleState = useCallback(() => {
    window.localStorage.removeItem(idleKey);
  }, []);

  const handleLogin = useCallback(() => {
    oktaClient.login(redirectUrl);
    resetIdleState();
  }, [resetIdleState, redirectUrl]);

  const handleLogout = useCallback(() => {
    oktaClient.logout();
  }, []);

  const automaticLogout = useCallback(() => {
    setUserIsIdle();
    handleLogout();
  }, [handleLogout, setUserIsIdle]);

  return {
    automaticLogoutBcData,
    automaticLogout,
    getUserIsIdle,
    handleLogin,
    handleLogout,
    setUserIsIdle,
  };
};

export default useAuth;
