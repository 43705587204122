import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  fromUnitsToRem,
  YodaColors,
  YodaFontWeight,
  YodaJustifyContent,
  YodaSpacing,
} from 'yoda-ui/yodaTheme';

export const gridListStyle: SxProps<Theme> = {
  '& .MuiDataGrid-main': {
    height: '790px',
  },
};

export const gridListHeadStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: YodaJustifyContent.spaceBetween,

  '& .MuiButton-root': {
    marginLeft: 'auto',
  },
};

export const gridListCardStyle: SxProps<Theme> = {
  padding: '0',
};

export const gridListFiltersStyle: SxProps<Theme> = {
  marginBottom: fromUnitsToRem(YodaSpacing.medium),

  '& > .MuiBox-root': {
    display: 'inline-block',
    width: '300px',
    marginRight: fromUnitsToRem(YodaSpacing.small),
  },
};

export const gridListDataStyle: SxProps<Theme> = {
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'hidden',
  },

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    paddingBottom: YodaSpacing.small,
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: YodaColors.blue5,
    color: YodaColors.white,
    borderRadius: '5px 5px 0 0',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },

  '& .MuiDataGrid-columnHeadersInner': {
    width: 'calc(100% - 30px)',
    transform: 'translate3d(15px, 0, 0) !important',
  },

  '& .MuiDataGrid-columnHeader:first-of-type, & .MuiDataGrid-cell:first-of-type':
    {
      paddingLeft: fromUnitsToRem(YodaSpacing.medium),
    },

  '& .MuiDataGrid-columnHeader:last-child, & .MuiDataGrid-cell:last-child': {
    paddingRight: fromUnitsToRem(YodaSpacing.medium),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },

  '& .MuiDataGrid-row': {
    boxShadow: `0 0 0 1px ${YodaColors.gray2}`,
    borderBottom: 'none',
    transition: 'box-shadow .2s ease-out, background-color .2s ease-out',
    width: 'calc(100% - 30px)',
    transform: 'translateX(15px)',
    borderRadius: '5px',
    fontWeight: YodaFontWeight.light,
  },

  '& .MuiDataGrid-row:hover': {
    backgroundColor: YodaColors.blue2,
    boxShadow: `0 0 7px 1px ${YodaColors.gray2}`,
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },

  '& .gridlist-cell-bold': {
    fontWeight: YodaFontWeight.medium,
  },

  '& .MuiDataGrid-row:first-of-type': {
    marginTop: YodaSpacing.small,
  },

  '& .MuiDataGrid-footerContainer': {
    paddingLeft: fromUnitsToRem(YodaSpacing.medium),
    paddingRight: fromUnitsToRem(YodaSpacing.medium),
  },

  '& .MuiDataGrid-footerContainer > div:first-of-type': {
    display: 'none',
  },

  '& .MuiDataGrid-footerContainer > .MuiFormControl-root .MuiSelect-select': {
    paddingTop: fromUnitsToRem(YodaSpacing.xxxSmall),
    paddingBottom: fromUnitsToRem(YodaSpacing.xxxSmall),
  },
};
