import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { NBA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Rule } from 'generated/nba.types';
import { RuleFragment } from 'modules/nba/graphql/fragments/rule.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const createRuleMutationCall = gql`
mutation createRule($createRule: CreateRuleInput!, $sendToSnowflake: Boolean!) {
  createRule(createRuleInput: $createRule, sendToSnowflake: $sendToSnowflake) {
    ${RuleFragment}
  }
}
`;

type ResultType = {
  createRule: Rule;
};

type UseCreateRuleMutation = [
  (
    rule: Partial<Rule>,
    sendToSnowflake?: boolean
  ) => Promise<Rule | undefined | null>,
  MutationResult<ResultType>,
];

export const useCreateRuleMutation = (): UseCreateRuleMutation => {
  const [createRule, state] = useMutation<ResultType>(createRuleMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: NBA_GRAPHQL_ENDPOINT },
  });
  // const flags = useFlags();

  const executeMutation = useCallback(
    async (rule, sendToSnowflake = true) => {
      try {
        const { data } = await createRule({
          variables: {
            createRule: rule,
            sendToSnowflake,
          },
        });
        return data?.createRule;
      } catch (error: unknown) {
        if (error instanceof Error) {
          const customContext = extractApolloErrorDetails(error, rule);
          Logger.logError({
            error,
            customContext,
            transactionName: 'createRule.ts - useCreateRuleMutation',
          });
        }
        return null;
      }
    },
    [createRule],
  );

  return [executeMutation, state];
};
