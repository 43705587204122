import { useLocation } from 'react-router-dom';
import { MODULES_CONFIG } from 'basics/constants/common.constants';
import { ConfigModule } from 'basics/enums/general.enums';
import { ModuleConfigValue } from 'basics/types/config.types';

export const useGetConfig = () => {
  const location = useLocation();
  const currentURL = location.pathname.split('/')[1];
  const modules = Object.values(MODULES_CONFIG);
  const currentModule = modules.find(
    (config: ModuleConfigValue) => (config.module === currentURL),
  ) || MODULES_CONFIG[ConfigModule.Default as keyof typeof MODULES_CONFIG];

  return currentModule;
};
