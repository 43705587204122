import { GridCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useRuleDashboardListConfig from './RuleDashboardList.config';
import {
  nbaRuleCreateRoute,
  nbaRuleEditRoute,
} from 'basics/constants/routes.constants';
import { Rule } from 'generated/nba.types';
import { PAGINATION_PAGE_SIZE } from 'modules/nba/basics/constants/common.constant';
import useRetrieveRulesQuery from 'modules/nba/graphql/queries/retrieveRules';

const useRuleDashboardList = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState] = useState(0);
  const [rules, setRules] = useState<Rule[]>([]);
  const navigate = useNavigate();

  const { data, loading } = useRetrieveRulesQuery();

  useEffect(() => {
    if (!loading) {
      setRules(data?.retrieveRules || []);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const { rulesListColumnConfig } = useRuleDashboardListConfig();

  const onCellClick = (params: GridCellParams) => {
    navigate(`${nbaRuleEditRoute}?id=${params?.row?._ID}`);
  };

  const menuRuleLabel = 'Rule';
  const newRuleClick = () => {
    navigate(nbaRuleCreateRoute);
  };
  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', {
      elementTitle: menuRuleLabel,
    }),
    newElementClick: newRuleClick,
  };

  return {
    rulesListColumnConfig,
    rules,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
    newElementButton,
    page,
    pageSize,
  };
};

export default useRuleDashboardList;
