import { Autocomplete } from '@mui/material';
import { FC, useCallback } from 'react';
import TextField from '../TextField';
import { SelectOption } from 'basics/options/options.types';
import { findOptionById } from 'basics/options/options.utils';
import { useYodaField } from 'services/yodaForm';
import { isValidId } from 'services/yodaForm/yodaForm.types';
import FormHelperText from 'yoda-ui/Form/FormHelperText';
import {
  selectStyle,
  textFieldStyle,
} from 'yoda-ui/Form/SelectYoda/SelectYoda.styles';
import { SelectYodaInputProps } from 'yoda-ui/Form/SelectYoda/SelectYoda.types';
import Paper from 'yoda-ui/Paper';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const SelectYoda: FC<SelectYodaInputProps> = ({
  onChange,
  defaultValue,
  label,
  name,
  options,
  placeholder,
  disabled,
  disableClearable,
  required,
  validation,
  maxHeight,
  overflow,
}) => {
  const {
    onChangeFieldSynthetic,
    fieldValue,
    fieldDefaultValue,
    fieldShowError,
    fieldErrorMessage,
  } = useYodaField({ name, defaultValue, onChange, validation });

  const findCurrentOptionById = useCallback(
    (id: string | number): SelectOption | null => findOptionById(options, id),
    [options],
  );

  return (
    <>
      <Autocomplete
        data-testid="select-yoda"
        PaperComponent={
          ({ children }) => (
            <Paper
              margin={ YodaSpacing.small }
              maxHeight={ maxHeight }
              overflow={ overflow }
            >
              { children }
            </Paper>
          )
        }
        sx={ selectStyle }
        disabled={ disabled }
        disablePortal
        value={ fieldValue ? findCurrentOptionById(fieldValue as string) : null }
        id={ name }
        onChange={ onChangeFieldSynthetic }
        options={ options.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0)) }
        defaultValue={
          fieldDefaultValue && isValidId(fieldDefaultValue)
            ? findCurrentOptionById(fieldDefaultValue)
            : null
        }
        disableClearable={ disableClearable }
        getOptionLabel={ (option) => option.label }
        renderInput={
          (params) => (
            <TextField
              { ...params }
              sx={ textFieldStyle }
              label={ label }
              placeholder={ placeholder }
              required={ required }
              error={ fieldShowError }
            />
          )
        }
      />
      {
        fieldShowError && (
          <FormHelperText error>{ fieldErrorMessage }</FormHelperText>
        )
      }
    </>
  );
};

export default SelectYoda;
