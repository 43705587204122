import { addDays, addHours, format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import isSameDay from 'date-fns/fp/isSameDay';
import { TestFunction } from 'yup';
import { TIME_ONE_MINUTE_IN_SECONDS } from 'basics/constants/common.constants';
import { TimeZone } from 'basics/enums/timeZoneList.enums';
import { getOffsetTime } from 'basics/options/timezone.options';
import { YodaFieldValue } from 'services/yodaForm/yodaForm.types';
import { DateRange } from 'yoda-ui/Form/DateRangePicker';

export const LONG_WEEKDAY_LONG_DAY_LONG_MONTH = 'iiii dd MMMM';
export const SHORT_WEEKDAY_LONG_DAY_LONG_MONTH = 'iii dd MMMM';
export const SHORT_WEEKDAY_LONG_DAY_SHORT_MONTH = 'iii dd MMM';
export const LONG_DAY_NUM_MONTH_SHORT_YEAR = 'dd.MM.yy';
export const LONG_DAY_NUM_MONTH_LONG_YEAR_SLASHES = 'dd/MM/y';
export const HOURS_MINUTES = 'HH:mm';
export const HOURS_MINUTES_SECONDS = 'HH:mm:ss';

export const timeToObject = (time: string) => {
  const splitTime = time.split(':');
  return {
    hour: Number(splitTime[0]),
    minutes: Number(splitTime[1]),
  };
};

export const getStartTimeFromDate = (date: Date, gapHours = 0) => {
  const now = new Date();
  const nowWithGap = addHours(now, gapHours);
  const dateWithGap = addHours(date, gapHours);
  const checkIsToday = isSameDay(nowWithGap, dateWithGap);
  const checkIsTodayWithGap = isSameDay(addDays(now, 1), nowWithGap);
  return (checkIsToday || checkIsTodayWithGap) ? format(nowWithGap, 'HH:mm') : '00:00';
};

export const getShortFormatDateTimeFromIsoString = (isoString: string, timeFormat: string) => format(parseISO(isoString), timeFormat);

export const isLaterDay = (date1: Date, date2: Date) => {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  return date1 > date2;
};

export const getUTCDate = (date?: Date, time?: string, timeZone: TimeZone = TimeZone['Europe/Paris']) => {
  if (!date || !time) {
    return null;
  }
  const { hour, minutes } = timeToObject(time);
  const localDate = new Date(date);
  localDate.setHours(hour, minutes, 0, 0);

  return zonedTimeToUtc(localDate, timeZone);
};

const getDisplayDateAndTime = (date: Date | string, includeDate = true) => {
  const isoDate = new Date(date).toISOString();
  const timeString = getShortFormatDateTimeFromIsoString(getOffsetTime(isoDate), HOURS_MINUTES);
  if (!includeDate) {
    return timeString;
  }
  const dateString = getShortFormatDateTimeFromIsoString(getOffsetTime(isoDate), SHORT_WEEKDAY_LONG_DAY_SHORT_MONTH);
  return `${dateString} \u00B7 ${timeString}`;
};

export const getHyphenedEventStartAndEndDate = (startDate: Date | string, endDate?: Date | string) => {
  let dateString = getDisplayDateAndTime(startDate);
  if (endDate) {
    dateString += ' - ';
    dateString += getDisplayDateAndTime(endDate, !isSameDay(new Date(startDate), new Date(endDate)));
  }
  return dateString;
};

export const testStartDateIsSet: TestFunction<DateRange | undefined> = (dateRange) => {
  const startDate = (dateRange || [null])[0];
  return !!startDate;
};

export const testStartDateNotInPast: TestFunction<DateRange | undefined> = (dateRange) => {
  const startDate = (dateRange || [null])[0];
  if (!startDate) {
    return true;
  }
  return !!startDate && !isLaterDay(new Date(), startDate);
};

export const transformInputDateUTC = (date: YodaFieldValue) => {
  if (date) {
    const [dateStart, dateEnd] = date;
    if (dateStart && dateEnd) {
      return {
        dateStart: getUTCDate(dateStart, `${dateStart.getHours()}:${dateStart.getMinutes()}`, TimeZone.UTC) as YodaFieldValue,
        dateEnd: getUTCDate(dateEnd, `${dateEnd.getHours()}:${dateEnd.getMinutes()}`, TimeZone.UTC) as YodaFieldValue,
      };
    }
  }

  return {};
};

export const testEndDayNotBeforeStartDay: TestFunction<DateRange | undefined> = (dateRange) => {
  if (!dateRange) {
    return true;
  }

  const now = new Date();
  const [startDate, endDate] = dateRange;
  if (endDate === null || (startDate === null && !isLaterDay(now, endDate))) {
    return true;
  }
  return !isLaterDay(startDate || now, endDate);
};

export const testEndDayAfterStartDay: TestFunction<DateRange | undefined> = (dateRange) => {
  if (!dateRange) {
    return true;
  }

  const now = new Date();
  const [startDate, endDate] = dateRange;
  if (endDate === null || (startDate === null && !isLaterDay(now, endDate))) {
    return true;
  }

  return isLaterDay(endDate, startDate || now);
};

export const testDateRangeInValidationRange = (dateRange: DateRange, validationRange: DateRange): boolean => {
  if (!validationRange || !dateRange) {
    return false;
  }

  const [startDate, endDate] = dateRange;
  const [validationStartDate, validationEndDate] = validationRange;

  return !!(startDate && endDate && validationStartDate && validationEndDate) && (startDate >= validationStartDate
    && startDate <= endDate
    && endDate <= validationEndDate);
};

type SplitSecondsTimeInMinAndSec = (args: {
  time: number;
  minutesLabel?: string;
  secondsLabel?: string;
}) => string;
export const splitSecondsTimeInMinAndSec: SplitSecondsTimeInMinAndSec = ({ time, minutesLabel, secondsLabel }) => {
  const minutes = Math.floor(time / TIME_ONE_MINUTE_IN_SECONDS);
  const seconds = time % TIME_ONE_MINUTE_IN_SECONDS;

  const formattedMinutes = minutes ? `${minutes} ${minutesLabel || 'min'}` : '';
  const formattedSeconds = seconds ? `${seconds.toString().padStart(2, '0')} ${secondsLabel || 'sec'}` : '';

  if (minutes && seconds) {
    return `${formattedMinutes} ${formattedSeconds}`;
  }
  if (minutes) {
    return formattedMinutes;
  }
  if (seconds) {
    return formattedSeconds;
  }
  return '';
};
