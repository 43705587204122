import { GridRowSpacingParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { PAGINATION_PAGE_SIZE } from 'modules/nba/basics/constants/common.constant';

const useGridList = () => {
  const [pageSize, setPageSize] = useState<number>(PAGINATION_PAGE_SIZE);

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 10,
      bottom: params.isLastVisible ? 0 : 10,
    };
  }, []);

  return {
    pageSize,
    setPageSize,
    getRowSpacing,
  };
};

export default useGridList;
