import { FC } from 'react';
import useRuleDashboardList from './RuleDashboardList.hook';
import GridList from 'modules/nba/components/GridList/GridList';

const RuleDashboardList: FC = () => {
  const {
    rulesListColumnConfig,
    rules,
    loading,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    onCellClick,
    newElementButton,
  } = useRuleDashboardList();

  return (
    <GridList
      getRowId={ (row) => row?._ID }
      loading={ loading }
      columnConfig={ rulesListColumnConfig }
      rows={ rules }
      onPageChange={ onPageChange }
      onPageSizeChange={ onPageSizeChange }
      rowCountState={ rowCountState }
      disableColumnMenu={ true }
      onCellClick={ onCellClick }
      newElementButton={ newElementButton }
    />
  );
};

export default RuleDashboardList;
