import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

export const dashboardSummaryStats: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'start',
  gap: YodaSpacing.xxLarge,
};

export const gridListDashboard: SxProps<Theme> = {
  marginTop: YodaSpacing.large,
  display: 'flex',
  justifyContent: 'center',
  gap: YodaSpacing.large,

  '& > *': {
    width: '100%',
  },
};
