import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RuleFormCancelCallbackType,
  RuleFormSubmitCallbackType,
} from './RuleForm.types';
import { Rule } from 'generated/nba.types';
// import { CiStatus } from 'modules/maya/basics/enums/maya.enums';
// import { ciToBE } from 'modules/maya/basics/transformers/CustomerInitiative.transformer';
import { ruleToBE } from 'modules/nba/basics/transformers/Rule.transformer';
// import useCustomerInitiativeFormConfig from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.config';
import useRuleFormConfig from 'modules/nba/components/RuleForm/RuleForm.config';
// import {
//   CiFormCanceltCallbackType,
//   CiFormSubmitCallbackType,
// } from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm.types';
// import useGetStrategicImperativeByIdQuery from 'modules/maya/graphql/queries/getStrategicImperativeById';
import { useYodaCreateForm } from 'services/yodaForm';
import { ButtonType } from 'yoda-ui/Button/Button.types';

const useRuleCreate = (
  rule: Rule | undefined,
  submitCallback: RuleFormSubmitCallbackType,
  cancelCallback: RuleFormCancelCallbackType,
) => {
  const { t } = useTranslation();

  const {
    getValues,
    providerFields,
    resetForm,
    // useWatchForm,
    // setValue,
    // useWatchField,
    setFieldShowError,
  } = useYodaCreateForm();
  // const { isValid } = useWatchForm();

  const [loading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { ruleFormConfig } = useRuleFormConfig(rule);

  if (rule) {
    const fieldsName = Object.keys(ruleFormConfig);
    setFieldShowError(true, fieldsName);
  }

  //   useEffect(() => {
  //     if (customerInitiativeFormConfig?.strategicImperative?.options.length) {
  //       setLoading(false);
  //     }
  //   }, [customerInitiativeFormConfig?.strategicImperative?.options, setLoading]);

  //   const currentStrategicImperative = useGetStrategicImperativeByIdQuery(selectedSi).data;
  //   useEffect(() => {
  //     if (currentStrategicImperative) {
  //       setValue(customerInitiativeFormConfig.therapeuticArea.name, currentStrategicImperative?.therapeuticArea);
  //       setSiLoading(false);
  //     }
  //   }, [currentStrategicImperative, customerInitiativeFormConfig.therapeuticArea.name, setValue]);

  //   const therapeuticAreaKey = useWatchField(customerInitiativeFormConfig.therapeuticArea.name)?.value;
  //   useEffect(() => {
  //     setSelectedTA(therapeuticAreaKey);
  //   }, [therapeuticAreaKey]);

  const handleCancelRuleForm = () => {
    resetForm();
    cancelCallback();
  };

  const handleSaveRuleForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedRule = ruleToBE(formValues);
    await submitCallback(formattedRule);

    setIsSubmitting(false);
  };

  const cancelButtonConfig = {
    buttonProps: {
      onClick: handleCancelRuleForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      // onClick: handleSaveCustomerInitiativeForm,
      onClick: handleSaveRuleForm,
      buttonType: ButtonType.primary,
      disabled: isSubmitting,
    },
    label: t('actions_save_label'),
    loading: isSubmitting,
  };

  return {
    cancelButtonConfig,
    ruleFormConfig,
    loading,
    providerFields,
    submitButtonConfig,
  };
};

export default useRuleCreate;
