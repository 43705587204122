import { FC } from 'react';
import useRuleCreate from './RuleCreate.hook';
import Heading from 'modules/nba/components/Heading';
import MayaLayout from 'modules/nba/components/NbaLayout';
import RuleForm from 'modules/nba/components/RuleForm/RuleForm';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';

const CustomerInitiativeCreate: FC = () => {
  // const { t } = useTranslation();
  const { loading, handleSaveRuleForm, handleCancelRuleForm } = useRuleCreate();

  return (
    <MayaLayout>
      <Heading>{ 'Create rule' }</Heading>

      {
        loading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Loader center />
          </Box>
        ) : (
          <RuleForm
            { ...{
              submitCallback: handleSaveRuleForm,
              cancelCallback: handleCancelRuleForm,
            } }
          />
        )
      }
    </MayaLayout>
  );
};

export default CustomerInitiativeCreate;
