import { gql, MutationResult, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { NBA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { Rule } from 'generated/nba.types';
import { RuleFragment } from 'modules/nba/graphql/fragments/rule.fragments';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

export const updateRuleMutationCall = gql`
mutation updateRule($id: String!, $updateRule: UpdateRuleInput!, $sendToSnowflake: Boolean!) {
  updateRule(id: $id, updateRuleInput: $updateRule, sendToSnowflake: $sendToSnowflake) {
    ${RuleFragment}
  }
}
`;

type ResultType = {
  updateRule: Rule;
};

type UseUpdateRuleMutation = [
  (
    id: string | undefined | null,
    rule: Partial<Rule>,
    sendToSnowflake?: boolean
  ) => Promise<Rule | undefined | null>,
  MutationResult<ResultType>,
];

export const useUpdateRuleMutation = (): UseUpdateRuleMutation => {
  const [updateRule, state] = useMutation<ResultType>(updateRuleMutationCall, {
    fetchPolicy: 'network-only',
    context: { clientName: NBA_GRAPHQL_ENDPOINT },
  });
  // const flags = useFlags();

  const executeMutation = useCallback(
    async (id, rule, sendToSnowflake = true) => {
      try {
        const { data } = await updateRule({
          variables: {
            id,
            updateRule: rule,
            sendToSnowflake,
          },
        });
        return data?.updateRule;
      } catch (error: unknown) {
        if (error instanceof Error) {
          const customContext = extractApolloErrorDetails(error, rule);
          Logger.logError({
            error,
            customContext,
            transactionName: 'updateRule.ts - useUpdateRuleMutation',
          });
        }
        return null;
      }
    },
    [updateRule],
  );

  return [executeMutation, state];
};
