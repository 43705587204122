import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

export const nbaLayoutStyle: SxProps<Theme> = {
  width: '100vw',
  marginBottom: -YodaSpacing.xLarge,
  flexGrow: 1,
};

export const nbaLayoutContentStyle: SxProps<Theme> = {
  position: 'relative',
  flex: 3,
  padding: YodaSpacing.xxLarge,
  paddingBottom: YodaSpacing.xxxxLarge,
  backgroundColor: YodaColors.blue1,
  overflow: 'auto',
};
