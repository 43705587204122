import { FC } from 'react';
import useRuleEdit from './RuleEdit.hook';
// import { mayaCustomerInitiativeEditRoute } from 'basics/constants/routes.constants';
// import { VeevaSyncStatus } from 'modules/maya/basics/enums/maya.enums';
// import CustomerInitiativeForm from 'modules/maya/components/CustomerInitiativeForm/CustomerInitiativeForm';
import Heading from 'modules/nba/components/Heading';
import NbaLayout from 'modules/nba/components/NbaLayout';
import RuleForm from 'modules/nba/components/RuleForm/RuleForm';
// import VeevaStatusBar from 'modules/maya/components/VeevaStatusBar/VeevaStatusBar';
import Box from 'yoda-ui/Box';
import Loader from 'yoda-ui/Loader';

const RuleEdit: FC = () => {
  // const { t } = useTranslation();
  const {
    loading,
    rule,
    // currentURL,
    handleSaveRuleForm,
    handleCancelRuleForm,
  } = useRuleEdit();
  return (
    <NbaLayout>
      <Heading>{ `Edit Rule: Snowflake ID #${rule?._ID}` }</Heading>
      {
        loading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Loader center />
          </Box>
        ) : (
          <RuleForm
            { ...{
              rule,
              submitCallback: handleSaveRuleForm,
              cancelCallback: handleCancelRuleForm,
            } }
          />
        )
      }
    </NbaLayout>
  );
};

export default RuleEdit;
