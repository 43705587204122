import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { homeRoute } from 'basics/constants/routes.constants';
import { succesToast, warningToast } from 'basics/utils/toast';
import { RuleFormSubmitCallbackType } from 'modules/nba/components/RuleForm/RuleForm.types';
import { useCreateRuleMutation } from 'modules/nba/graphql/mutations/createRule';

const useRuleCreate = () => {
  const navigate = useNavigate();

  const [createRule] = useCreateRuleMutation();

  // const urlParams = useMemo(
  //   () => new URLSearchParams(window.location.search),
  //   [],
  // );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const handleCancelRuleForm = () => {
    navigate(homeRoute);
  };

  const handleSaveRuleForm: RuleFormSubmitCallbackType = async (
    ruleFormData,
  ) => {
    const ruleData = await createRule(
      {
        ...ruleFormData,
      },
      true,
    );

    if (ruleData) {
      succesToast('Rule created');
      navigate(homeRoute);
    } else {
      navigate(homeRoute);
      warningToast('Cannot create rule');
    }
  };

  return {
    loading,
    handleSaveRuleForm,
    handleCancelRuleForm,
  };
};

export default useRuleCreate;
