import useIdleTimerDialog from './IdleTimerDialog.hook';
import DefaultDialogTitle from 'components/Dialogs/DefaultDialogTitle';
import { TextButton } from 'yoda-ui/BiogenLincDS/Buttons';
import Box from 'yoda-ui/Box';
import Dialog from 'yoda-ui/Dialogs/Dialog';
import DialogContent from 'yoda-ui/Dialogs/Dialog/DialogContent';
import { YodaBorderRadius, YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const dialogStyle = {
  '& .MuiPaper-rounded': {
    padding: YodaSpacing.xxLarge,
  },
};

const IdleTimerDialog = () => {
  const { dialogContent, idleDialogIsOpen, t } = useIdleTimerDialog();

  return (
    <Dialog
      open={ idleDialogIsOpen }
      borderRadius={ YodaBorderRadius.medium }
      sx={ dialogStyle }
    >
      <DefaultDialogTitle fontSize={ YodaFontSize.xxLarge }>
        { t('idleTimerDialog_title') }
      </DefaultDialogTitle>
      <Box marginBottom={ YodaSpacing.xxxxLarge }>
        <DialogContent fontWeight={ YodaFontWeight.light }>
          { dialogContent }
        </DialogContent>
      </Box>
      <Box display="flex" justifyContent="center">
        <TextButton label="Stay connected" />
      </Box>
    </Dialog>
  );
};

export default IdleTimerDialog;
