import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { NBA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { warningToast } from 'basics/utils/toast';
import { getConfigVar, isProduction } from 'config/config';
import oktaClient from 'services/okta';

const authLink = setContext(async (req, { headers }) => ({
  ...headers,
  headers: {
    authorization: ['Bearer', oktaClient?.token?.accessToken].join(' '),
    source: 'okta',
  },
}));

type ErrorExtensionsType = {
  code: string;
  response?: {
    error: string;
    message: string;
    statusCode: number;
  };
};

const errorLink = onError(({ graphQLErrors = [] }) => {
  graphQLErrors.forEach((err) => {
    const code = err.extensions ? Number((err.extensions as ErrorExtensionsType)?.response?.statusCode) : 500;
    switch (code) {
      case 401:
      case 403: {
        oktaClient.logout();
        break;
      }
      default: {
        if (!isProduction) {
          warningToast(`Apollo error: ${err?.message}`);
        }
      }
    }
  });
});

const nbaHttpUploadLink = (createUploadLink({ uri: getConfigVar('REACT_APP_NBA_GRAPHQL_URI') }) as unknown) as ApolloLink;
const nbaLink = ApolloLink.from([authLink, errorLink, nbaHttpUploadLink]);

const cache = new InMemoryCache({ addTypename: false });

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === NBA_GRAPHQL_ENDPOINT,
    nbaLink,
  ),
  cache,
});
export default client;
